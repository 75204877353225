import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  statusFilter: 'all',
  mailingID: 0,
  mailingTitle: '',
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'expectedDelivery',
      label: 'Expected Delivery',
      aria: 'Expected Delivery',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'deliveryType',
      label: 'Delivery Type',
      aria: 'Delivery Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'recipient',
      label: 'Recipient',
      aria: 'Recipient',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'recipientAddress',
      label: 'Recipient Address',
      aria: 'Recipient Address',
      width: '25%',
      sortable: true,
      searchable: true
    },
    {
      field: 'email',
      label: 'Recipient Email',
      aria: 'Recipient Email',
      width: '25%',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      aria: 'Status',
      width: '25%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
