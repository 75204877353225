<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('deliveryItemsList.title') }}</div>
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>
        <div class="level-item">
          <b-button label="Back to Mail Management" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>
    <div class="level pl-2">
      <div class="level-left level-item subtitle">{{ mailingTitle }}</div>
      <div class="level-right">
        <b-field tabindex="0" aria-label="status filter">
          <b-radio v-model="statusFilter" name="name" native-value="all" aria-label="show all">
            All
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="processing"
            aria-label="filter further"
          >
            Processing
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="mailed"
            aria-label="filter further"
          >
            Mailed
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="delivered"
            aria-label="filter further"
          >
            Delivered
          </b-radio>
        </b-field>
      </div>
    </div>

    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'MailingList',
  components: {
    PageContent,
    ModernTable
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  watch: {
    statusFilter: 'reload',
    hoaId() {
      this.reload()
    }
  },

  i18n: {
    messages: {
      en: {
        deliveryItemsList: {
          title: 'Mail Delivery Items'
        }
      }
    }
  }
}
</script>

<style></style>
