<template>
  <PageContentLayoutOnly headerColor="#fafafa">
    <div itemdetails class="notification headerColor" v-if="formData">
      <div class="box is-12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="DeliveryType"
                      label="Delivery Type"
                      type="text"
                      vid="DeliveryType"
                      placeholder="Delivery Type"
                      spellcheck="true"
                      aria-label="Delivery Type"
                      class="is-small"
                      v-model="formData.deliveryType"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="SourceDocument"
                      label="Source Document"
                      type="text"
                      vid="SourceDocument"
                      placeholder="Source Document"
                      spellcheck="true"
                      aria-label="Source Document"
                      class="is-small"
                      v-model="formData.sourceDocument"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openSourceDocument()"
                      :disabled="loading"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6">
                    <valid-input
                      style="width:50px !important;"
                      name="Recipient"
                      label="Recipient"
                      type="text"
                      vid="Recipient"
                      placeholder="Recipient"
                      spellcheck="true"
                      aria-label="Recipient"
                      class="is-small"
                      v-model="formData.recipient"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-5">
                    <valid-input
                      style="width:50px !important;"
                      name="AddressedDocument"
                      label="Addressed Document"
                      type="text"
                      vid="AddressedDocument"
                      placeholder="Addressed Document"
                      spellcheck="true"
                      aria-label="Addressed Document"
                      rules="max:8000"
                      class="is-small"
                      v-model="formData.addressedDocument"
                      :disabled="true"
                    />
                  </div>
                  <div class="column is-1" style="margin-top: 26px;">
                    <button
                      type="button"
                      @click.prevent="openAddressedDocument()"
                      :disabled="loading"
                      class="button is-link is-light"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8">
                    <valid-input
                      style="width:50px !important;"
                      name="RecipientAddress"
                      label="Recipient Address"
                      type="text"
                      vid="RecipientAddress"
                      placeholder="Recipient Address"
                      spellcheck="true"
                      aria-label="Recipient Address"
                      class="is-small"
                      v-model="formData.recipientAddress"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8">
                    <valid-input
                      style="width:50px !important;"
                      name="RecipientEmail"
                      label="Recipient Email"
                      type="text"
                      vid="RecipientEmail"
                      placeholder="Recipient Email"
                      spellcheck="true"
                      aria-label="RecipientEmail"
                      class="is-small"
                      v-model="formData.recipientEmail"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8">
                    <valid-input
                      style="width:50px !important;"
                      name="ExpectedDeliveryDate"
                      label="Expected Delivery Date"
                      type="text"
                      vid="ExpectedDeliveryDate"
                      placeholder="Expected Delivery Date"
                      spellcheck="true"
                      aria-label="ExpectedDeliveryDate"
                      class="is-small"
                      v-model="formData.expectedDeliveryDate"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8">
                    <valid-input
                      style="width:50px !important;"
                      name="ScheduledSendTime"
                      label="Scheduled Send Time"
                      type="text"
                      vid="ScheduledSendTime"
                      placeholder="Scheduled Send Time"
                      spellcheck="true"
                      aria-label="ScheduledSendTime"
                      class="is-small"
                      v-model="formData.scheduledSendTime"
                      :disabled="true"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8">
                    <valid-input
                      style="width:50px !important;"
                      name="Status"
                      label="Status"
                      type="text"
                      vid="Status"
                      placeholder="Status"
                      spellcheck="true"
                      aria-label="Status"
                      class="is-small"
                      v-model="formData.status"
                      :disabled="true"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
    <Modal :toggle.sync="toggle">
      <SourceDocumentModal
        :ID="parseInt(mailDeliveryItemID)"
        :doc="mailDeliveryItem"
        :documentViewOnly="true"
        downloadType="SourceDocument"
      />
    </Modal>
    <Modal :toggle.sync="toggle2">
      <AddressedDocumentModal
        :ID="parseInt(mailDeliveryItemID)"
        :doc="mailDeliveryItem"
        :documentViewOnly="true"
        downloadType="AddressedDocument"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import SourceDocumentModal from './components/index'
import AddressedDocumentModal from './components/index'
import Modal from '@/components/Modal'
import { data } from './keys/data'
import { methods } from './keys/methods'

// components
import ValidInput from '@/components/inputs/ValidInput'

export default {
  name: 'UpdateWorkOrder',
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly,
    SourceDocumentModal,
    AddressedDocumentModal,
    Modal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.reload()
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        if (this.isDebug == true) console.debug('...in toggle()')
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    },
    toggle2() {
      if (this.toggle2 == false) {
        if (this.isDebug == true) console.debug('...in toggle2()')
        this.toggle2 = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reload()
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: {
        itemDetail: { title: 'Delivery Item Detail' }
      }
    }
  }
}
</script>

<style lang="scss">
#update-work-order .my-grid-class {
  height: 400px;
}

#update-work-order tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
