import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { mailingManagementStore } from '@/services/MailingManagement/store'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'LL'

    let filteredList = list

    if (this.isQuery === true) {
      this.radio = 'query'

      if (this.queryMailingType && this.queryMailingType !== '') {
        filteredList = filteredList.filter(f => f.mailingTypeID === this.queryMailingType)
        if (this.isDebug === true)
          console.debug(
            'mailing type = ' + this.queryMailingType + ' - ' + JSON.stringify(filteredList)
          )
      }

      if (this.queryDescription && this.queryDescription !== '') {
        filteredList = filteredList.filter(
          f =>
            f.description != null &&
            f.description !== '' &&
            f.description.indexOf(this.queryDescription) >= 0
        )
        if (this.isDebug === true)
          console.debug(
            'mailing description = ' + this.queryDescription + ' - ' + JSON.stringify(filteredList)
          )
      }

      if (this.queryStatus && this.queryStatus !== '') {
        filteredList = filteredList.filter(
          f => f.status != null && f.status !== '' && f.status.indexOf(this.queryStatus) >= 0
        )
        if (this.isDebug === true)
          console.debug('status = ' + this.queryStatus + ' - ' + JSON.stringify(filteredList))
      }

      if (
        this.queryFromCreatedDate &&
        this.queryToCreatedDate &&
        this.queryFromCreatedDate !== '' &&
        this.queryToCreatedDate !== '' &&
        this.queryFromCreatedDate != 'null/null/null' &&
        this.queryToCreatedDate != 'null/null/null'
      ) {
        filteredList = filteredList.filter(
          f =>
            moment(f.createdDate) >= moment(this.queryFromCreatedDate) &&
            moment(f.createdDate) <= moment(this.queryToCreatedDate)
        )
        if (this.isDebug === true)
          console.debug(
            'from to dates = ' +
              this.queryFromCreatedDate +
              ' - ' +
              this.queryToCreatedDate +
              ' -- ' +
              JSON.stringify(filteredList)
          )
      }
    }

    var rows = filteredList.map(entry => {
      const dateCreated = parseDate(_get(entry, 'createdDate', null), dateFormat)
      const mailingID = _get(entry, 'mailingID', 0)
      const description = _get(entry, 'description', '')
      const mailingType = _get(entry, 'mailingType.name', '')
      const status = _get(entry, 'status', '')

      return {
        mailingID,
        mailingType: mailingType,
        description: description,
        status: status,
        dateCreated: {
          date: dateCreated,
          format: dateFormat
        },
        items: {
          component: Button,
          props: {
            onClick: () => {
              this.processItems(entry)
            },
            text: 'Items'
          }
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    console.debug('in reload...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await mailingManagementStore
      .dispatch('getMailingList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    loadingComponent.close()

    this.loading = false
  },

  async reloadRequests() {
    console.debug('in reloadRequests...')

    this.isQuery = false

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.statusFilter && this.statusFilter == 'query') {
      this.isQuery = true
    }

    await this.loadMailingTypes()

    await this.reload()

    loadingComponent.close()
  },

  async loadMailingTypes() {
    if (this.isDebug == true) console.debug('in loadMailingTypes()...')
    await mailingManagementStore.dispatch('loadMailingTypes').then(({ list }) => {
      this.mailingTypes = list
    })
  },

  async processSearch() {
    if (this.isDebug === true) console.debug('...in processSearch')

    if (this.radio && this.radio == 'query') {
      if (this.isDebug === true)
        console.debug('this.$refs.fromCreatedDateComponent=' + this.$refs.fromCreatedDateComponent)

      if (
        this.$refs.fromCreatedDateComponent &&
        this.$refs.toCreatedDateComponent &&
        this.$refs.fromCreatedDateComponent != undefined &&
        this.$refs.toCreatedDateComponent != undefined
      ) {
        const from = this.$refs.fromCreatedDateComponent.getValue()
        const to = this.$refs.toCreatedDateComponent.getValue()
        this.queryFromCreatedDate = `${from.month}/${from.day}/${from.year}`
        this.queryToCreatedDate = `${to.month}/${to.day}/${to.year}`

        if (this.isDebug === true)
          console.debug(
            'from to dates=' + this.queryFromCreatedDate + ' = ' + this.queryToCreatedDate
          )
      }
    }

    await this.reload()
  },

  processItems(mailing) {
    if (mailing) {
      this.$router.push({
        name: 'maildeliveryitems',
        params: {
          id: mailing.mailingID,
          mailingid: mailing.mailingID,
          title: mailing.description
        }
      })
    }
  }
}
