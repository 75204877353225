import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  statusFilter: 'all',
  isQuery: false,
  queryFromCreatedDate: null,
  queryToCreatedDate: null,
  queryMailingType: null,
  queryDescription: '',
  queryStatus: '',
  mailingTypes: [],
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'mailingID',
      numeric: true,
      label: 'Mailing ID',
      aria: 'Mailing  ID',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'mailingType',
      label: 'Mailing Type',
      aria: 'Mailing Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '35%',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      aria: 'Status',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'dateCreated',
      date: true,
      label: 'Date Created',
      aria: 'Date Created',
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
