import moment from 'moment'

export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    file: null,
    uploadMimeTypes: 'application/pdf',
    uploadMimeText: 'a PDF',
    base64pdf: '',
    scale: 1,
    searchTerm: -1,
    imageContent: null,
    htmlContent: null,
    description: '',
    title: 'Create',
    attachment: false,
    docFile: null,
    docDocument: null,
    uploadeDocumentFile: null,
    pageCount: [],
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}
