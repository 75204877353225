import { deliveryItemsStore } from '@/services/MailingManagement/MailDeliveryItems/store'
import $ from 'jquery'

export const methods = {
  async reload() {
    if (this.isDebug == true) console.debug('doc=' + JSON.stringify(this.doc))

    if (this.onlyPhotos && this.onlyPhotos === true) {
      this.uploadMimeTypes = 'image/jpeg, image/png, image/gif'
      this.uploadMimeText = 'an image: (PNG, JPEG, or GIF)'
    }

    if (
      this.doc &&
      (this.doc.sourceDocumentID || this.doc.addressedDocumentID) &&
      this.doc.mailingDeliveryItemID > 0
    ) {
      this.title = 'Update'

      this.description = this.doc.description
      if (
        this.doc.sourceDocumentID &&
        this.doc.sourceDocumentID != undefined &&
        this.downloadType &&
        this.downloadType === 'SourceDocument'
      ) {
        this.attachment = true
        this.loadDocument(true)
      } else if (
        this.doc.addressedDocumentID &&
        this.doc.addressedDocumentID != undefined &&
        this.downloadType &&
        this.downloadType === 'AddressedDocument'
      ) {
        this.attachment = true
        this.loadDocument(false)
      }
    } else {
      this.title = 'Create'
      this.description = ''
      this.attachment = false
    }

    this.numPages = this.pdf.numPages
  },

  async loadDocument(isSource) {
    if (!this.doc || !this.doc.sourceDocumentID || this.doc.sourceDocumentID <= 0) {
      return
    }

    try {
      const payload = {
        mailingDeliveryItemID: this.doc.mailingDeliveryItemID,
        sourceDocumentID: isSource === true ? this.doc.sourceDocumentID : null,
        addressedDocumentID: isSource === false ? this.doc.addressedDocumentID : null,
        asBase64: true
      }

      console.debug('loadDocument params=' + JSON.stringify(payload))

      await deliveryItemsStore
        .dispatch('getDocument', {
          params: payload
        })
        .then(({ result }) => {
          if (result) {
            this.searchTerm = result.indexOf('application/pdf')

            if (this.isDebug == true) console.debug('deliveryItemsStore getDocument=' + result)
            if (this.isDebug == true)
              console.debug('image or pdf............this.searchTerm:' + this.searchTerm)

            if (this.searchTerm != -1) {
              this.base64pdf = result
              this.imageContent = null
              this.htmlContent = null
            } else {
              this.searchTerm = result.indexOf('text/html')
              if (this.searchTerm != -1) {
                this.imageContent = null
                this.base64pdf = null
                const rawResult = result.replace('data:text/html;base64,', '')
                this.htmlContent = atob(rawResult)
              } else {
                this.imageContent = result
                this.base64pdf = null
                this.htmlContent = null
              }
            }
          }
        })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  open() {
    this.loading = false
  },

  closeModal() {
    this.leaveForm
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}
