import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { deliveryItemsStore } from '@/services/MailingManagement/MailDeliveryItems/store'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'MM/DD/YYYY hh:mm a'

    let filteredList = list

    if (this.statusFilter !== 'all') {
      this.radio = 'query'

      if (this.statusFilter === 'processing') {
        filteredList = filteredList.filter(
          f =>
            f.status &&
            f.status != null &&
            f.status.replace(/^\s+|\s+$/gm, '').toLowerCase() == 'pending mailing'
        )
        if (this.isDebug === true) console.debug('processing = ' + JSON.stringify(filteredList))
      } else if (this.statusFilter === 'mailed') {
        filteredList = filteredList.filter(
          f =>
            (f.status && f.status != null && f.status.toLowerCase() === 'mailed') ||
            f.status.toLowerCase() === 'emailed'
        )
        if (this.isDebug === true) console.debug('mailed = ' + JSON.stringify(filteredList))
      } else if (this.statusFilter === 'delivered') {
        filteredList = filteredList.filter(
          f => f.status && f.status != null && f.status.toLowerCase() === 'delivered'
        )
        if (this.isDebug === true) console.debug('delivered = ' + JSON.stringify(filteredList))
      }
    }

    var rows = filteredList.map(entry => {
      const expectedDelivery = parseDate(_get(entry, 'expectedDeliveryDate', null), dateFormat)
      const deliveryType = _get(entry, 'deliveryType', '')
      const recipient = _get(entry, 'recipientName', '')
      const recipientJsonString = _get(entry, 'recipientAddressJSON', '')
      let recipientAddress = ''

      if (recipientJsonString) {
        const address = JSON.parse(recipientJsonString)
        console.debug('address=' + JSON.stringify(address))

        if (address) {
          recipientAddress = `${address.AddressLine1 || ''} ${address.AddressLine2 ||
            ''}, ${address.City || ''}, ${address.State || ''}  ${address.Zip || ''}`
        }
      }

      const email = _get(entry, 'recipientEmailAddresses', '')

      const status = _get(entry, 'status', '')

      return {
        expectedDelivery: expectedDelivery,
        deliveryType: deliveryType,
        recipient: recipient,
        recipientAddress: recipientAddress,
        email: email,
        status: status,
        items: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(entry)
            },
            text: 'Detail'
          }
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    console.debug('in reload...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      if (this.$route.params.id) {
        this.mailingID = this.$route.params.id
      }

      if (this.$route.params.title) {
        this.mailingTitle = this.$route.params.title
      }

      console.debug('mailingID=' + this.mailingID)

      await deliveryItemsStore
        .dispatch('getMailDeliveryItemsList', {
          mailingID: this.mailingID
        })
        .then(({ list }) => {
          this.determineRows(list)
          this.loading = false
        })
    } catch (ex) {
      console.debug(ex.message)
    }

    loadingComponent.close()

    this.loading = false
  },

  processDetail(deliveryItem) {
    if (deliveryItem) {
      const path = `/detailitemtabs/${deliveryItem.mailingDeliveryItemID}/${deliveryItem.mailingID}/${this.mailingTitle}/itemDetails#tab`
      this.$router.push(path)
    }
  },

  returnToParent() {
    this.$router.push({
      name: 'mailings'
    })
  }
}
