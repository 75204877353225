/*
  import { mailingManagementStore } from '@/services/MailingManagement/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await mailingManagementStore.dispatch ('getMailingList', {
      hoaId
    });
  */
  async getMailingList({}, { hoaId }) {
    console.log('in getMailingList...')
    try {
      const result = await kms.get('/Mailings/Mailing/List', {
        params: {
          hoaId
        }
      })

      if (isDebug == true) console.debug('getMailingList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The mailing management list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async loadMailingTypes({}) {
    console.log('in loadMailingTypes...')
    try {
      const result = await kms.get('/Mailings/MailingType/List')

      let mapped = []
      if (result.results) {
        mapped = result.results
          .map(v => ({
            value: v.mailingTypeID ? v.mailingTypeID : 0,
            label: v.name ? v.name : ''
          }))
          .filter(f => f.label.length > 0 && f.value > 0)
          .sort((a, b) => a.label - b.label)
      }

      mapped.unshift({ value: 0, label: '<-Mailing Types->' })

      if (isDebug == true) console.debug('loadMailingTypes=' + JSON.stringify(mapped))

      return {
        list: mapped
      }
    } catch (exception) {
      notifyProblem(`The Mailing Type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const mailingManagementStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
