export const methods = {
  async reload() {
    this.loading = true

    this.mailDeliveryItemID = this.$route.params.id || 0
    this.mailingID = this.$route.params.mailingid || 0
    this.deliveryItemTitle = this.$route.params.title || ''

    console.debug(this.mailingID + ', mailDeliveryItemID=' + this.mailingID)

    this.loading = false
  },

  returnToParent() {
    console.debug('returnToParent=' + this.mailingID)

    this.$router.push({
      name: 'maildeliveryitems',
      params: {
        id: this.mailingID,
        mailingid: this.mailingID,
        title: this.deliveryItemTitle
      }
    })
  }
}
