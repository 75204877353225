/*
  import { deliveryItemsStore } from '@/services/MailingManagement/MailDeliveryItems/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = false

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await deliveryItemsStore.dispatch ('getMailDeliveryItemsList', {
      mailingID
    });
  */
  async getMailDeliveryItemsList({}, { mailingID }) {
    console.log('in getMailDeliveryItemsList...')
    try {
      const result = await kms.get('/Mailings/MailingDeliveryItem/List', {
        params: {
          mailingID
        }
      })

      if (isDebug == true) console.debug('getMailDeliveryItemsList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The mail delivery items list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMailDeliveryItemById({}, { mailDeliveryItemID }) {
    try {
      console.debug('in getMailDeliveryItemById...')

      const mailDeliveryItem = await kms.get(`/Mailings/MailingDeliveryItem/${mailDeliveryItemID}`)

      if (isDebug == true)
        console.debug('getMailDeliveryItemById=' + JSON.stringify(mailDeliveryItem))

      return {
        result: mailDeliveryItem
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the mail delivery item.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getDocument({}, { params }) {
    try {
      const download = await kms.get(`/Mailings/MailingDeliveryItem/GetDocumentFile`, {
        params
      })

      return {
        result: download
      }
    } catch (exception) {
      console.error(exception)
    }

    return {
      result: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const deliveryItemsStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
