<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('mailingEventsList.title') }}</div>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { mapState, mapGetters } from 'vuex'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'MailingEventsList',
  components: {
    PageContent,
    ModernTable
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  watch: {},

  i18n: {
    messages: {
      en: {
        mailingEventsList: {
          title: 'Event Time'
        }
      }
    }
  }
}
</script>

<style></style>
