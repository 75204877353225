import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import { mailingEventStore } from '@/services/MailingManagement/MailEvents/store'

export const methods = {
  determineRows: function(list) {
    const dateFormat = 'MM/DD/YYYY hh:mm a'

    let filteredList = list

    var rows = filteredList.map(entry => {
      const eventDate = parseDate(_get(entry, 'eventTime', null), dateFormat)
      const description = _get(entry, 'eventTypeDescription', '')

      return {
        description: description,
        eventDate: {
          date: eventDate,
          format: dateFormat
        }
      }
    })

    this.rows = rows
  },
  async reload() {
    console.debug('in reload...')
    this.loading = true

    this.mailDeliveryItemID = this.$route.params.id

    if (this.isDebug == true) console.debug('mailDeliveryItemID=' + this.mailDeliveryItemID)

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await mailingEventStore
      .dispatch('getMailingEventList', {
        mailingDeliveryItemID: this.mailDeliveryItemID
      })
      .then(({ list }) => {
        this.determineRows(list)
        this.loading = false
      })

    loadingComponent.close()

    this.loading = false
  }
}
