<template>
  <PageContent>
    <div class="level pl-2">
      <div class="level-left level-item title">{{ $t('mailingList.title') }}</div>
      <div class="level-right">
        <b-field tabindex="0" aria-label="mailing management status filter">
          <b-radio v-model="statusFilter" name="name" native-value="all" aria-label="show all">
            All
          </b-radio>
          <b-radio
            v-model="statusFilter"
            name="name"
            native-value="query"
            aria-label="filter further"
          >
            Search
          </b-radio>
        </b-field>
      </div>
    </div>
    <div class="pl-2" v-if="isQuery && isQuery == true">
      <div class="pb-5">
        <div class="pt=5">
          <b-field>
            <span>
              <DatePicker
                label="Created Dates"
                ref="fromCreatedDateComponent"
                ariaPrefix="from"/>&nbsp;&nbsp;&nbsp;&nbsp;
              <DatePicker
                label="To"
                ref="toCreatedDateComponent"
                ariaLabel="to date"
                ariaPrefix="to"/></span
            ><span style="padding-top:30px !important;" class="pt-5 pl-5">
              <b-button
                class="float-right go-button"
                size="is-small"
                type="is-info"
                rounded
                @click="processSearch"
                >Search</b-button
              ></span
            >
          </b-field>
        </div>
        <div class="pt-5 pb-5">
          <b-field>
            <span class="pr-5" style="width: 250px" aria-label="Select G/L Expense">
              <label class="label">Mailing Type</label>
              <valid-select
                placeholder="Mailing Types"
                vid="queryMailingType"
                v-model="queryMailingType"
                aria-selected="<-Mailing Types->"
              >
                <option v-for="option in mailingTypes" :value="option.value" :key="option.value">{{
                  option.label
                }}</option>
              </valid-select>
            </span>
            <span>
              <valid-input
                name="Description"
                label="Description"
                type="text"
                vid="Description"
                placeholder="Description"
                spellcheck="true"
                aria-label="Description"
                class="is-small"
                v-model="queryDescription"
              />
            </span>
            <span class="pl-5">
              <valid-input
                name="Status"
                label="Status"
                type="text"
                vid="Status"
                placeholder="Status"
                spellcheck="true"
                aria-label="Status"
                class="is-small"
                :aria-required="true"
                v-model="queryStatus"
              />
            </span>
          </b-field>
        </div>
      </div>
    </div>
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      />
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { mapState, mapGetters } from 'vuex'
import DatePicker from '@/components/inputs/DatePicker'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'MailingList',
  components: {
    PageContent,
    ModernTable,
    DatePicker,
    ValidSelect,
    ValidInput
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,

  methods,

  mounted: function() {
    data, (this.loading = true)
    this.reload()

    this.loading = false
  },

  watch: {
    statusFilter: 'reloadRequests',
    hoaId() {
      this.reload()
    }
  },

  i18n: {
    messages: {
      en: {
        mailingList: {
          title: 'Mailing Management'
        }
      }
    }
  }
}
</script>

<style></style>
