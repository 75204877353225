import { deliveryItemsStore } from '@/services/MailingManagement/MailDeliveryItems/store'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'

var DEFAULT_FORM_VALUES = {
  deliveryType: '',
  sourceDocument: '',
  recipient: '',
  addressedDocument: '',
  recipientAddress: '',
  recipientEmail: '',
  expectedDeliveryDate: '',
  scheduledSendTime: '',
  status: ''
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reload() {
    this.loading = true

    this.mailDeliveryItemID = this.$route.params.id
    this.deliveryItemTitle = this.$route.params.title

    if (this.isDebug == true) console.debug('detailItemID=' + this.mailDeliveryItemID)
    if (this.isDebug == true) console.debug('deliveryItemTitle=' + this.deliveryItemTitle)

    this.formData = { ...DEFAULT_FORM_VALUES }

    await deliveryItemsStore
      .dispatch('getMailDeliveryItemById', {
        mailDeliveryItemID: this.mailDeliveryItemID
      })
      .then(({ result }) => {
        if (result) {
          try {
            const dateFormat = 'MM/DD/YYYY hh:mm a'

            this.mailDeliveryItem = result

            this.formData.deliveryType = _get(result, 'deliveryType', '')

            //May need document in dto
            this.formData.sourceDocument = _get(result, 'sourceDocumentName', '')
            this.formData.addressedDocument = _get(result, 'addressDocumentName', '')
            this.formData.recipient = _get(result, 'recipientName', '')

            const recipientJsonString = _get(result, 'recipientAddressJSON', '')

            if (recipientJsonString) {
              const address = JSON.parse(recipientJsonString)
              console.debug('address=' + JSON.stringify(address))

              if (address) {
                this.formData.recipientAddress = `${address.AddressLine1 ||
                  ''} ${address.AddressLine2 || ''}, ${address.City || ''}, ${address.State ||
                  ''}  ${address.Zip || ''}`
              }
            }

            this.formData.recipientEmail = _get(result, 'recipientEmailAddresses', '')
            this.formData.expectedDeliveryDate = parseDate(
              _get(result, 'expectedDeliveryDate', null),
              dateFormat
            )
            this.formData.scheduledSendTime = parseDate(
              _get(result, 'scheduledSendTime', null),
              dateFormat
            )
            this.formData.status = _get(result, 'status', '')
          } catch (e) {
            console.debug('error while loading the mail delivery item data:' + e)
          }
        }
      })

    this.loading = false
  },

  openSourceDocument() {
    console.debug('openSourceDocument...')
    this.toggle = true
  },

  openAddressedDocument() {
    this.toggle2 = true
  },

  returnToParent() {
    this.$router.push({
      name: 'maildeliveryitems',
      params: {
        id: this.mailDeliveryItem.mailingDeliveryItemID,
        mailingid: this.mailDeliveryItem.mailingID,
        title: this.deliveryItemTitle
      }
    })
  }
}
